<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }} 編集</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="subtitle-1 font-weight-bold mb-1">
            {{ title }}
          </div>
          <v-text-field
            v-if="type==='text'"
            v-model="input"
            outlined
            dense
            :placeholder="placeholder"
            :rules="rules"
          ></v-text-field>
          <v-select
            v-else-if="type==='select'"
            v-model="input"
            :items="items"
            dense
            solo
            :rules="rules"
          ></v-select>
          <v-switch
            v-else-if="type==='bool'"
            v-model="input"
            :label="input ? '確認済み' : '未確認'"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog=false">キャンセル</v-btn>
        <v-btn color="primary" @click="send" :loading="loading">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  props: {
    value: {},
    type: {
      required: true,
      validator: function (value) {
        return ['text', 'select', 'bool'].indexOf(value) !== -1
      },
    },
    userid: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    keyname: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
    },
    appmetadata: {
      type: Boolean,
      default: false,
    },
    // selectフィールド時の項目
    items: {
      type: Array,
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    send() {
      if (this.$refs.form.validate()) {
        this.save()
      }
    },
    async save() {
      this.loading = true
      let form = {}
      if (this.appmetadata) {
        let app_metadata = {} 
        app_metadata[this.keyname] = this.input
        form.app_metadata = app_metadata
      } else {
        form[this.key] = this.input
      }
      const accessToken = await this.$auth.getTokenSilently()
      await axios.patch(`/api/users/${this.userid}`, form, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response) => {
        if (!response.data.error) {
          this.dialog = false
          this.$emit('success', response.data)
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false
      })
    },
  }
}
</script>